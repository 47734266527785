import Vue from 'vue'
import Vuex from 'vuex'

import {Fetch} from '@/helpers/functions'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    log:false,

    version:0,

    root_api:process.env.VUE_APP_ROOT_API,

    feriados:[
      '2020/12/07',
      '2020/12/08',
      '2020/12/24',
      '2020/12/25',
      '2020/12/31',
      '2021/01/01',
      '2021/02/15',
      '2021/02/16',
      '2021/03/24',
      '2021/04/02',
      '2021/05/24',
      '2021/05/25',
      '2021/06/21',
      '2021/07/09',
      '2021/08/16',
      '2021/10/08',
      '2021/10/11',
      '2021/11/22',
      '2021/12/08',
      '2021/12/24',
      '2021/12/31',
    ],

    fRendicion:[],

    route:"/",

    fMin:null,
    fMax:null,
    
    _cfg:{
      diasCheques:30,
      dias:15,
      dias_min:[],
      dias_max:[],
      diasMCheques:60,
      importeCheques:{
        datos:{min :7000,max:9999999, msg:'El importe debe ser mayor igual que 7000'},
        prueba:{min:7000,max:45000, msg:'El importe debe ser mayor igual que 7000 y menor que 45000'},
        ajuste:{min:2000 ,max:6999, msg:'El importe debe ser mayor igual que 2000'},
      },
      plazoCheque:30,
      plazoChequeDto:30,
      plazoChequeExtra:0,
    },

    login:false,

    _session:{
      idUsr:null,
      viajante:"",
      tipoUsr:2, //2 Viajante  3 Admin
      ComboBox:"v-combobox",
    },

  },
  getters: {
  //computeds
    getVersion: state => {
      return state.version
    },

    getFMin: state => {
      return state.fMin
    },
    getFMax: state => {
      return state.fMax
    },

    getImporteCheques: state => {
      return state["_cfg"].importeCheques
    },

    getRoute: state => {
      return state.route
    },

    getFRendicion: state => {
      return state.fRendicion
    },

    ROOT_API (state) {
      return state.root_api
    },

    CFG (state){
      return state["_cfg"]
    },

    USR (state){
      return state["_session"]
    },

    ComboBox (state){
      return state["_session"].ComboBox
    },
  },
  mutations: {
  //methods sincronos  

    setVersion (state, val){
      state.version=val
    },

    setFMin (state, val){
      state.fMin=val
    },
    setFMax (state, val){
      console.log('-----------------')
      console.log(val)
      console.log(state)
      console.log('-----------------')
      state.fMax=val
    },

    
    setPlazoCheque (state,{plazoMax=40,plazoMaxDto=40}) {
      state["_cfg"].plazoCheque=plazoMax
      state["_cfg"].plazoChequeDto=plazoMaxDto
    },


    setDiasMCheques (state, dias){
      state["_cfg"].diasMCheques=dias
    },

    setPlazoChequeExtra (state,plazo) {
      state["_cfg"].plazoChequeExtra=plazo
    },


    setImporteCheques (state, val){
      console.log("cambio cheques --> val")
      state["_cfg"].importeCheques=val
    },

    setFRendicion (state, val){
      if(state.fRendicion.length==0){
        state.fRendicion.push(val)
      }else{
        let idx = state.fRendicion.findIndex( x=> x == val )
        console.log("idx --> ",idx)
        idx==-1 && state.fRendicion.push(val)
      }
    },

    setDiasCheques(state,val){
      state["_cfg"].diasCheques=val || 30
    }, 


    setDiasMin (state, val){
      state["_cfg"].dias_min[val.ori]=val.dias
    },
    setDiasMax (state, val){
      state["_cfg"].dias_max[val.ori]=val.dias
    },


    setDataFilter (state, val) {
      state.dataFilter[val.name]=val.value
    },
    addCarrito (state,item){
      state.carrito.push(item)
    },

    setRoute (state, val){
      state.route = val
      localStorage.setItem('route', val)
    },

    setLogin (state,val){
      state.login=val.login

      state._session=val.usr


      console.log("USER-->",val.usr)
      state["_cfg"].plazoChequeExtra = val.usr.idUsr==13 ? 5 : 0
      

      console.log("plazoChequeExtra-->",state["_cfg"].plazoChequeExtra)

      


      //state=this.$store.state.usr=r


      /*

      state.header = val.componente

      state.user={
        apellido: val.apellido,
        email: val.email,
        nombre: val.nombre,
        token: val.token,
        tusrID: val.tusrID,
        login:true,
        redir:val.tusrID==2 ? '/vendedor':'/comprador'
      }

      localStorage.setItem('_SESSION_', val.token)

      console.log("state.user -->", state.user)
      */

      //console.log(state)
    },

    setLogout (state) {
    
      state.login=false
      state._session=null
      localStorage.clear()   
      
    },

  },
  actions: {
    //methods asincronos
    async ['getFechasRendicion'] ({commit,state},viajanteID){
      let opt={
        mode: 'cors',
        method:'get'
      }

      let response = await Fetch(`${state.root_api}/recibos/rendicion/${viajanteID}`, opt)

        
      if (response && response.ok){
        let r = await response.json()

        console.log("FECHAS---->", r)
        
        for await (let i of r.rows){
          commit("setFRendicion",i.fecha)
        }
      }
      else{
        console.log("Errorrrrr")
      }
    }
  },
  modules: {
  },
})
