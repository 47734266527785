<template>
  <v-container class="ma-0 pa-0">
    <!--INICIO MENU MOVIL -->
    <v-navigation-drawer v-model="drawer"  temporary app >
      <v-list max-height=49 color="#cdcdcd" class="py-0">
        <v-list-item > 
          <!--
            <v-img 
            :alt="dataSite.title"
            :title="dataSite.title"
            class="shrink ml-n4 my-2"
            contain
            :src="require('../assets/logoJL.jpg')"
            transition="scale-transition"
            height="40"

            ></v-img>

            <span class="body-2 black--text">Repuestos JL</span>
          -->
          <v-spacer></v-spacer>
          <v-btn icon  @click.stop="drawer = !drawer" >
            <v-icon size="16">fa fa-close</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list >
          <template v-for="(item,index) in navItems" >
            <template>
              <v-list-item :to='item.url?item.url:null'  :link="!item.items" :class="item.items ? '' : ''" :key="index" >
                <v-list-item-content>
                  <v-list-item-title class="black--text caption text-uppercase" >{{ item.title }}</v-list-item-title>
                    <template v-for="(i,idx) in item.items">
                      <v-list-item  link  class="subItem ml-n2" :key="idx" @click="router_link(i.url)">
                        <v-list-item-content class="">
                          <v-list-item-title class="black--text caption ml-2 text-uppercase">{{ i.title }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon >
                          <v-icon small>fa fa-caret-right</v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                    </template>
                </v-list-item-content>
              </v-list-item>
            </template>
          </template>
      </v-list>

    </v-navigation-drawer>
    <!--FIN MENU MOVIL -->
    <v-app-bar app color="#cdcdcd" dense light>
      <div class="d-flex align-center">
        <div class="d-flex d-sm-flex d-md-none">
          <v-btn text icon mr-10 @click.stop="drawer = !drawer">
            <v-icon size="24">fa fa-navicon</v-icon>
          </v-btn>
        </div>
        <!--
        <v-img
          :alt="dataSite.title"
          :title="dataSite.title"
          class="shrink d-none d-md-flex "
          contain
          :src="require('../assets/logoJL.jpg')"
          transition="scale-transition"
          height="40"
          @click="link()"
        />
        -->
        <v-btn-toggle tile color="grey lighten-3" class="d-none d-md-flex" group >
          <template v-for="(item,index) in navItems" >
            <template v-if="!item.items">
            <router-link :to="item.url" style="text-decoration:none" class="linkPpal" :key="index">
              <v-btn text tile  class="px-5 black--text caption font-weight-bold ">{{item.title}}</v-btn>
            </router-link>
            </template>
            <template v-else>
              <div class="linkPpal" :key="index">
                <v-menu bottom  offset-y min-width=120>
                  <template v-slot:activator="{on}">
                      <v-btn v-on="on" text tile class="px-5 black--text caption font-weight-bold " >{{item.title}}</v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="(i, index) in item.items"
                      :key="index"
                      @click="router_link(i.url)"
                    >
                      <v-list-item-title class="black--text caption font-weight-bold">{{ i.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </template>
        </v-btn-toggle>
      </div>
      <v-spacer />
      <template>
        <v-menu auto tile offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="ml-5 mr-3" icon small color="secondary">
              <v-icon >fa fa-user</v-icon>
            </v-btn>
          </template>
          <v-list tile class="pa-0 ma-0">
            <v-subheader>
                <div class="d-flex ">
                  <v-icon  class="mx-2" small>fa fa-user-circle-o</v-icon>
                  <span class="caption mx-2 primary--text">{{this.$store.state._session.nameUsr}}</span>
                </div> 
            </v-subheader>
            <v-divider />
            <!--
            <router-link to="/config" style="text-decoration:none">
              <v-list-item  link>
                <v-list-item-icon>
                <v-icon small>fa fa-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-uppercase body-2 black--text">Configuración</v-list-item-title>
              </v-list-item>
            </router-link>
            -->
            <v-list-item  link @click="logout">
              <v-list-item-icon>
              <v-icon small>fa fa-power-off</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-uppercase body-2 black--text">Cerrar sesión</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-app-bar>
  </v-container>
</template>

<script>
import { METHODS } from 'http'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Header',

  data () {
      return {
      dataSite:{title:"title"},
      
      drawer: false,
      
      navItems: [
        {
          title:'INICIO',
          icon: '',
          url:'/home'
        },
        { 
          title: 'RECIBOS',
          url:"/recibos", icon: '',
          items:null
        },
        {title:'NUEVO RECIBO',url:'/recibo',items:null},
        { 
          title: 'PLANILLA CTRL',
          url:"/planilla_control/listado", icon: '',
          items:null
        }
      ],
    }
  },
  mounted:  function () {
    if (this.USR.tipoUsr==2 || this.USR.tipoUsr==3){
      this.navItems=[
          {
            title:'INICIO',
            icon: '',
            url:'/home'
          },
          { 
            title: 'RECIBOS',
            url:"", icon: '',
            items:[
              {title:'NUEVO RECIBO',url:'/recibo'},
              {title:'RECIBOS',url:'/recibos'},
            ]
          },
          { 
            title: 'PLANILLA CTRL',
            url:"", icon: '',
            items:[
              {title:'NUEVA PLANILLA',url:'/planilla_control/planilla'},
              {title:'PLANILLAS',url:'/planilla_control/listado'}
            ]
          },
      ]
    }

    this.navItems.push({title:'CTA CTE',url:'/ctacte',items:null},)

  },
  computed:{
    
    ...mapGetters(['USR']),
   
  },
  methods:{
    ...mapMutations (["setLogout"]),


    router_link(path) {
      this.$router.replace(path).catch((err)=>{})

    },

    logout(){
      
      this.setLogout()
      this.router_link('/')
    }
  }
};
</script>

<style lang="scss" scoped>
.v-btn {
text-transform: none !important;
}
.v-list {
    border-radius: 0px!important;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color:green!important;
}

.subItem_ {
  position: relative;
  &::before{
      display:block;
      position:absolute;
      content: '';
      width: 11px;
      height: 80%;
      right:-5px;
      top:10%;
      background-color:green !important;
      opacity: 1!important;
  }
}
.linkPpal {
  position: relative;
  &:after{
      display:block;
      content: '';
      border-bottom: solid 3px green; /* #019fb6;  */
      transition: transform 250ms ease-in-out;
  }

  &:after {
      transform: scaleX(0);  
  }

  &.active:after {
      transform: scaleX(1);  
      opacity: .5;
  }


  &:hover:after { 
      transform: scaleX(1); 
      color: green !important;
  }


}





</style>




