import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import es from 'vuetify/es5/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#2d6733',//'#01579B',//'#13367a',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#fd7e14',//'#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
  },
    lang: {
      locales: { es },
      current: 'es',
    },
  icons: {
    iconfont: ['fa','fab'],
    values:{
      checkboxOff:"fa-square-o",
      checkboxOn:"fa-check-square-o ",
      checkboxIndeterminate:"fa-minus-square-o",

      dropdown:"fa-angle-down",
      prev:"fa-angle-left",
      next:"fa-angle-right",

      error: "fa-exclamation-triangle",

      clear:"fa-times",
      file:"fa-paperclip",


      expand:"fa-angle-down",
      subgroup:"fa-caret-down",

      delete:"fa-close",

      sort:"fa-caret-up",

      last:"fa-angle-double-right",
      first:"fa-angle-double-left",

      warning:"fa-exclamation-triangle",
      cancel:"fa-times",

    }
  },
});

/*
      indeterminate-icon="fa fa-square-o"
      off-icon="fa fa-square-o"
      on-icon="fa fa-square-o"
*/ 