<template>
    <v-main class="secondary lighten-5">
      <v-container
        class="fill-height "
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-5">
              <v-toolbar
                color="secondary lighten-5 secondary--text   elevation-1"
                
                
              >
                <v-toolbar-title>INGRESO</v-toolbar-title>
                <v-spacer></v-spacer>
                <span >v.{{$store.state.version}}</span>
                </v-toolbar>
                <v-divider />
              <v-alert type="error" dense transition="scale-transition" class="ma-2 caption" :value="alert">
                {{msgError}}
              </v-alert>
              <v-card-text>
                <v-form ref="frmLogIn"> 
                  <v-text-field
                    v-model="usr"
                    id="user"
                    label="Usuario"
                    placeholder="Ingrese su usuario"
                    ref="userInput"
                    name="user"
                    color="sencondary"
                    type="text"
                    autocomplete="off"
                    
                  />

                  <v-text-field
                    v-model="pass"
                    id="password"
                    label="Contraseña"
                    placeholder="Ingrese su contraseña"
                    name="password"
                    color="sencondary"
                    :type="txt2pass?'password':'text'"
                    autocomplete="off"
                    
                  >
                   <template v-slot:append>
                      <v-icon right  @click="txt2pass=!txt2pass" @mouseover="txt2pass=false"  @mouseout="txt2pass=true" style="cursor:pointer;"> {{`fa ${txt2pass?'fa-eye-slash':'fa-eye'}`}} </v-icon>
                   </template>

                  </v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <!--<v-btn color="secondary" @click="validUsr()" :disabled="(usr && pass)? false:true">Entrar</v-btn>-->
                <v-btn color="secondary" @click.native="validaUsr" >Entrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'

export default {
  name: 'LogIn',
  data: () => ({
    alert:false,
    usr:null,
    pass:null,
    msgError:null,

    txt2pass:true
  }),
  mounted:  function () {
    this.$refs.userInput.focus()    
  },
  computed: {
  ...mapGetters(['ROOT_API','CFG','USR']),
  },
  methods :{
    
    ...mapMutations(["setLogin"]),

    validaUsr: async function (){
      try {
        let opt={
            method: 'GET', 
            mode: 'cors'
          }
        let state=undefined

        let response=await fetch(`${this.ROOT_API}/usuario/authUsr?usr=${this.usr}&pass=${this.pass}`, opt)

        if (response && response.ok) {
          let r = await response.json()
          let login ={
            login:true,
            usr:{
              idUsr:r.userID,
              nameUsr:r.userName,
              viajante:r.userName,
              tipoUsr:r.userType, //0 Usuario / 2 Viajante  / 3 Admin
              ComboBox:"v-combobox",
            }
          }
          this.setLogin(login)

          localStorage.setItem('token', r.token)
          this.$router.push({name:'home'}).catch(err => {})

        }
        else {
          throw("Login invalid")
        }
      }
      catch (err) {
        localStorage.setItem('token', "")
        localStorage.setItem('route', "")

        this.msgError="Usuario/Contraseña inválido"
        this.alert=true
        setTimeout(e=>this.alert=false, 3000)
        this.$refs.userInput.focus()
        this.$store.state.usr=undefined
      }   
    }
  },
};
</script>
