import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';


//import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'font-awesome/css/font-awesome.css'


import {format} from 'date-fns'
import MyUtil from './plugins/util'




Array.prototype.chunk = function (chunk_size) {
  console.log("fn chunk")
  if ( !this.length ) {
      return [];
  }

  return [ this.slice( 0, chunk_size ) ].concat(this.slice(chunk_size).chunk(chunk_size));
};

!!!process.env.VUE_APP_CONSOLE ? console.log = function() {} : console.log("LOG ACTIVADO")


Vue.use(MyUtil)

Vue.config.productionTip = false



const calculoTotal = (arr,filter,field)=>{
  let r=arr.filter( item => item.ori == filter || filter=='')
  return ((r && r.length>0)  ? r.map(function(i) { return parseFloat(i[field]) }).reduce((res, item) => res=res + item) : 0).toFixed(2)     
}  

Vue.filter('subtotal', (arr, filter,field) => calculoTotal(arr,filter,field))


Vue.filter('LEN', function (value) {
  return value.length
})

Vue.filter ('DDMMAAAA_HHMM', function (value) {
  if (!value) return ''
  return format(value ?new Date(value): Date.now(), 'dd-MM-yyyy HH:mm:ss.00')
})

Vue.filter ('DDMMAAAA', function (value) {
  if (!value) return ''
  return format(value ?new Date(value): Date.now(), 'dd-MM-yyyy')
  //console.log("filter DDMMAAAA", value)
  //console.log(new Date(value))
  //return format(value ? value: Date.now() ,'dd-mm-yyyy')
})


//import { Plugins } from '@capacitor/core'
//const { SplashScreen } = Plugins


/*Vue.filter('num2Letters',(val=>num2letters(val)))*/


new Vue({
  router,
  store,
  vuetify,
  mounted() {
    //SplashScreen.hide()
  },
  render: h => h(App)
}).$mount('#app')
