<template>
  <v-app>
    <component :is="validaSession"></component>
  </v-app>
</template>

<script>

import Main from "@/components/Main"
import LogIn from "@/components/LogIn"

import packageInfo from '../package.json';




export default {
  name: 'App',
  
  components: {
    Main,
    LogIn,
  },

  computed:{
    validaSession:function (){
      return (this.$store.state.login ? 'Main':'LogIn')
    }
  },

  methods:{
    
  },

  data () {
    return {
    }
  },
  created: async function () {
    try {
      console.log("created")
      this.$store.commit('setVersion', packageInfo.version)
    }
    catch (err){
      console.log(err)
    }
  }

};
</script>

<style lang="scss">
  .v-menu__content {
        border-radius: 0!important;
  }

  .v-dialog {
   // margin: 12px!important;
  }

  .v-data-footer {
    margin-right: 0 !important;
  }

  .v-simple-checkbox .v-icon.v-icon {
    font-size: 16px !important;
  }
</style>
