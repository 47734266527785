import * as jwt from 'jsonwebtoken'
let payload={}
let T


let setPayload= (p) => {
  payload = p
}

let getToken = () => {
  /* __s3b4st14n__ */
  let {idUsr=-1,nameUsr="Valida API",tipoUsr="-1"} = payload
  const token = jwt.sign({idUsr,nameUsr,tipoUsr} , `__s3b4st14n__!`, {
    expiresIn: "45sec"
  })
  console.log("TOKEN-->", token)
  return token
}

let Fetch = async (url, opt={}) => {
  try {
    T=getToken()
    opt.headers ? opt.headers.Authorization= `Bearer ${T}` : opt.headers={Authorization:`Bearer ${T}`}
    
    console.log({url, opt})
    return await fetch(url, opt)
  }
  catch (e){
    console.log(e)
    throw e
  }
}

export { Fetch, setPayload };