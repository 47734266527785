import Vue from 'vue'
import VueRouter from 'vue-router'
import * as jwtDecode  from 'jwt-decode'
import {Fetch} from "@/helpers/functions"


/*import Recibos from "@/views/Recibos.vue"*/
//import Recibo from "@/views/Recibo.vue"*/
//import ValCheques from "@/views/ValCheques.vue"

import store from '@/store'

let ROOT_API = process.env.VUE_APP_ROOT_API

Vue.use(VueRouter)


async function redirectIfNotAuth (to, from, next) {
  try {
    let token = localStorage.getItem('token')
    //Validamos token
  
    let usr = store.state._session
  
    if (!token) throw("ERROR seccion privada")
  
    let opt= {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }
    }
    let response = await fetch(`${ROOT_API}/usuario/authUsr`, opt)
  
    if(response && response.ok) {
      let r=await response.json()
      if (r.token) {
        //asignamos nuevo token
        token=r.token
      }

      let decode = jwtDecode(token)
      let login ={
        login:true,
        usr:{
          idUsr:decode.userID,
          nameUsr:decode.userName,
          viajante:decode.userName,
          tipoUsr:decode.userType, //2 Viajante  3 Admin
          ComboBox:"v-combobox",
        }
      }


      response = await Fetch(`${ROOT_API}/config/RECIBO`,{ method: "GET", mode: "cors" });

      if (response && response.ok){
        let [cfg] = (await response.json()).filter(x => x.key=='VERSION')

        console.log("CFG-->",cfg.value)
        if (cfg.value!=store.state.version){
          alert("Error de versión. Debe actualizar el sistema")

          next("/")
          location.reload()
        }

        //let version = cfg.filter(x.)
        
        //if (cfg)
  
          
          //this.variables_config[el.key]=val
      }




      await store.commit("setLogin", login)

      localStorage.setItem('token', token)








    } 
    else {
      throw("ERROR seccion privada")
    }

  
    //SI TOKEN VALIDO && store.getUsr && store.getUsr.login => next()
  
    //SI TOKEN VALIDO && !store.getUsr => login por token and next(localStorage.getItem('route'))
    
  
    //TOKEN INVALIDO
    if (!usr) throw("ERROR seccion privada")
    //console.log("to-->", to)
    next()

  }
  catch (err) {
    console.log(err)

    await store.commit('setLogout')
    next("/")
  }   
}

const routes = [
  {path:'/'},
  {
    path: '/home',
    name: 'home',
    beforeEnter: redirectIfNotAuth,
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
  },
  {
    path: '/recibo',
    name: 'recibo',
    beforeEnter: redirectIfNotAuth,
    component: () => import(/* webpackChunkName: "recibo" */ '@/views/Recibos/Recibo.vue')
  },
  {
    path: '/recibos',
    name: 'recibos',
    beforeEnter: redirectIfNotAuth,
    component: () => import(/* webpackChunkName: "recibos" */ '@/views/Recibos/Recibos.vue')
  },

  {
    path: '/planilla_control/planilla',
    name: 'planilla',
    beforeEnter: redirectIfNotAuth,
    component: () => import(/* webpackChunkName: "planilla" */ '@/views/PlanillaCtrl/Planilla.vue')
  },
  {
    path: '/planilla_control/listado',
    name: 'planillas',
    beforeEnter: redirectIfNotAuth,
    component: () => import(/* webpackChunkName: "planillas" */ '@/views/PlanillaCtrl/Planillas.vue')
  },
  {
    path: '/ctas_cobrar/',
    name: 'ctas_cobrar',
    beforeEnter: redirectIfNotAuth,
    component: () => import(/* webpackChunkName: "ctas" */ '@/views/Ctas/CtaCobrar.vue')
  },
  {
    path: '/ctas_cobrar/:viajanteID',
    name: 'ctas_cobrar_vijanteID',
    props: true,
    beforeEnter: redirectIfNotAuth,
    component: () => import(/* webpackChunkName: "ctas" */ '@/views/Ctas/CtaCobrar.vue')
  },

  {
    path: '/ctacte',
    name: 'ctacte',
    props: true,
    beforeEnter: redirectIfNotAuth,
    component: () => import(/* webpackChunkName: "ctas" */ '@/views/Ctas/CtaCte.vue')
  },
  /*
  {
    path: '/valcheques',
    name: 'valcheques',
    component: ValCheques
  }
  */
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes
})


router.beforeEach((to, from, next) => {

  store.commit("setRoute",to.fullPath)

  //store.setRoute(to.fullPath)


  next();
})

export default router
