<template>
 <v-container fluid class=" ma-0 pa-0">
    <Header></Header>
    <v-container fluid class="mx-0 mt-12 mb-5 px-2">
        <router-view ></router-view>
    </v-container>
    <v-footer app color="grey lighten-2" class="mt-5 caption primary--text font-weight-light">
        Todos los derechos reservados. v{{version}} | ® 2020.<v-spacer />
    </v-footer>

  </v-container>
</template>

<script>
import Header from '@/components/Header';
import packageInfo from '../../package.json';

export default {
  name: 'Main',

  components: {
    Header,
  },

  methods:{
    reset() {this.$refs.form.reset()},
    
  },

  data () {
    return {
      version:packageInfo.version
    }
  },



}
</script>

<style>
.text-through {
    text-decoration: line-through;
}

.rlinks {
  text-decoration: none!important
}
.grilla .v-image__image {
  top:15px!important;
}

.v-pagination__navigation {
  font-size: 12px !important;

}

.fa-angle-right:before {
    font-size: 14px !important;
}
.fa-angle-left:before {
    font-size: 14px !important;
}

.v-badge__badge {
  font-size:11px !important;
}
.title_card {
  background-color: rgba(0, 0, 0, .3);
  height: 40px;
  margin: auto;
  padding: 5px!important
}
.normal14 {
  font-size: 14px!important
}
</style>
